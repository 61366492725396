import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import ApartmentsList from "../components/ApartmentsList/apartmentsList"
import WindowDisplay from "../components/WindowDisplay/windowDisplay"
import HeadingTextTwoColumns from "../components/HeadingTextTwoColumns/headingTextTwoColumns"
import Button from "../components/Button/Button"

import { H1 } from "../styles/typography"
import { main } from "../styles/theme"
import { gridLayout } from "../styles/mixins"
import { useGlobalAptSelectorOpen, useGlobalCurrentHouse } from "../globalState"

const HeadingWrapper = styled.div`
  margin: ${({ imagebelow }) => (imagebelow ? "46px 0 0" : "46px 0")};
  display: flex;
  flex-direction: column;

  > h1 {
    margin-bottom: 33px;
  }

  @media ${main.breakpoints.large} {
    margin: 196px 0 0;
    margin: ${({ imagebelow }) => (imagebelow ? "196px 0 0" : "196px 0 65px")};
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    > h1 {
      margin-bottom: 0;
    }
  }
`
const SecondImage = styled.div`
  ${gridLayout}

  > .gatsby-image-wrapper {
    grid-column: 1 / -1;
  }

  @media ${main.breakpoints.medium} {
    > .gatsby-image-wrapper {
      grid-column: 7 / span 5;
    }
  }

  @media ${main.breakpoints.large} {
    margin-top: 100px;
  }
`

const ApartmentType = ({ data }) => {
  const [, setCurrentHouse] = useGlobalCurrentHouse()
  const [, setAptSelectorOpen] = useGlobalAptSelectorOpen()
  const apartmentType = data.allSitePage.edges[0].node.context.pageContent.node
  const sanityApartments = data.allSitePage.edges[0].node.context.apartments
  const apartments = []
  sanityApartments.forEach(apartment => {
    apartments.push(apartment.node)
  })

  useEffect(() => {
    setCurrentHouse(apartmentType.house.slug.current)
  }, [apartmentType.house.slug, setCurrentHouse])

  return (
    <Layout
      backgroundColor={
        apartmentType.house.slug.current === "gardshuset" ? "blue" : "yellow"
      }
    >
      <Seo
        title={apartmentType.seoTitle}
        description={apartmentType.seoDescription}
      />
      <HeadingWrapper imagebelow={apartmentType.heroImage ? true : false}>
        <H1 size="large">{apartmentType.heading ?? apartmentType.type}</H1>
        <Button
          type="button"
          onClick={() => setAptSelectorOpen(true)}
          title="Öppna bostadsväljare"
          isInverted
        />
      </HeadingWrapper>
      {apartmentType.heroImage && (
        <GatsbyImage
          image={apartmentType.heroImage.asset.gatsbyImageData}
          alt={apartmentType.heroImage.altText ?? ""}
          style={{ marginTop: "40px" }}
        />
      )}
      <HeadingTextTwoColumns
        heading={apartmentType.preamble ?? ""}
        text={
          apartmentType.paragraph ? apartmentType.paragraph.split("\n") : []
        }
      />

      {apartmentType.secondImage && (
        <SecondImage>
          <GatsbyImage
            image={apartmentType.secondImage.asset.gatsbyImageData}
            alt={apartmentType.secondImage.altText ?? ""}
          />
        </SecondImage>
      )}

      <div style={{ paddingBottom: "1px" }}>
        <ApartmentsList data={apartments} />
      </div>
      {apartmentType.house.slug.current === "porthuset" && (
        <div style={{ paddingBottom: "100px" }}>
          <StaticImage
            src="../images/porthuset-terrass-cropped.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Privat uteplats"
          />
        </div>
      )}
      {apartmentType.house.slug.current === "gardshuset" && (
        <WindowDisplay paddingBottom />
      )}
    </Layout>
  )
}

export const query = graphql`
  query($path: String!) {
    allSitePage(
      filter: {
        path: { eq: $path }
        context: { pageType: { eq: "apartmentType" } }
      }
    ) {
      edges {
        node {
          path
          context {
            pageContent {
              node {
                type
                slug {
                  current
                }
                seoTitle
                seoDescription
                heading
                preamble
                paragraph
                heroImage {
                  altText
                  asset {
                    gatsbyImageData {
                      layout
                      width
                      height
                      backgroundColor
                      images {
                        fallback {
                          sizes
                          src
                          srcSet
                        }
                      }
                    }
                  }
                }
                secondImage {
                  asset {
                    gatsbyImageData {
                      layout
                      width
                      height
                      backgroundColor
                      images {
                        fallback {
                          sizes
                          src
                          srcSet
                        }
                      }
                    }
                  }
                }
                house {
                  slug {
                    current
                  }
                }
              }
            }
            apartments {
              node {
                slug {
                  current
                }
                _id
                area
                floor
                price
                aptId
                aptNumber
                status
                hireCharge
                title
                apartmentType {
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`
export default ApartmentType
